import React from "react"
import styled from "@emotion/styled"
import Project from "../projects/project"
import Flex from "../projects/templateflex"
import Image from "../components/image"
import { Helmet } from "react-helmet"

const Title = styled.h2`
  color: #5133a6;

  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 20px;
  flex: 0 0 95%;
  margin-left: 2.5%;
`
const SubTitle = styled.h3`
  color: #5133a6;

  font-size: 20px;
`
const Desc = styled.p`
  flex: 0 0 95%;
  margin-left: 2.5%;
  margin-bottom: 30px;
`
const Text = styled.p`
  height: auto;
`
const TextBox = styled.div`
  flex: 0 0 42.5%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(6) {
    order: 6;
  }
  &:nth-child(7) {
    order: 7;
  }
  @media (max-width: 760px) {
    flex: 0 0 95%;
    margin-left: 2.5%;
    &:nth-child(6) {
      order: 5;
    }
  }
`

const Img = styled.div`
  border: 0.01px #dcdcdc solid;
  box-shadow: 0px 18px 25px rgba(0, 0, 0, 0.15);
  flex: 0 0 42.5%;
  margin-bottom: 30px;
  &:nth-child(4) {
    order: 4;
  }
  &:nth-child(5) {
    order: 5;
  }
  &:nth-child(8) {
    order: 8;
  }

  @media (max-width: 760px) {
    flex: 0 0 95%;
    margin-left: 2.5%;
    &:nth-child(5) {
      order: 6;
    }
  }
`
const GamesWithHue = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Portfolio- Games with Hue</title>
      <link rel="canonical" href="https://avtar.dev/gameswithhue" />
    </Helmet>
    <Project>
      <Flex>
        <Title>Games with Hue</Title>
        <Desc>
          This project is a suite of kids games that uses the Philips Hue API to
          communicate with your smart home lighting to add an extra level of
          interaction to some well known games. I’ve started off with building
          ‘Red Light, Green Light!’, and ‘The Floor is Lava’ and ‘Musical
          Chairs’ are in the works. This project was very significant in my
          learning process of how to code and practice putting together a
          project from start to finish. More importantly, it helped me recognize
          and build my own abilities as a developer. I’ve been a big fan of the
          ‘internet of things’ but was somewhat disappointed at the limited
          application of smart devices I owned myself. With some enouragement, I
          started to tackle the project myself and decided to develop Games with
          Hue. I’m super proud of what I’ve learned and worked on so far.{" "}
        </Desc>
        <TextBox>
          <SubTitle>Learning CSS Animation</SubTitle>
          <Text>
            Learning CSS Animation It was very cool to see how I could make some
            simple shapes and sprites come to life with different applications
            of CSS. My particular favorite was the ‘Bulbie' animation. I used
            CSS step animations and react state and was able to make them run
            across the screen!
          </Text>
        </TextBox>
        <Img>
          {" "}
          <Image
            fileName="games-1.png"
            style={{ width: "100%" }}
            alt="picture of step animation + red light game bg"
          />
        </Img>
        <Img>
          {" "}
          <Image
            fileName="games-2.png"
            style={{ width: "100%" }}
            alt="image or timer component"
          />
        </Img>
        <TextBox>
          {" "}
          <SubTitle>Asynchronous?</SubTitle>
          <Text>
            I finally realized how confusing it can be to have so many
            time-sensitive functions firing at the same time in JS. It made it
            more satisfying to find a solution that solved all these problems
            for the timing in Red Light, Green Light! I found a way (not so
            elegant) to make the bulbs switch between stopped and running as
            well as the lights switch between red and green. These actions
            happen all while having the bulbies cross the finish line at the
            same time whilst having different paces!
          </Text>
        </TextBox>
        <TextBox>
          <SubTitle>Future Improvements</SubTitle>
          <Text>
            While I was beyond happy in finishing the first game, I also saw
            that there were a lot of places where I could improve and optimize
            my code. While I did strive to make reusable components for my game
            console and buttons, I ended up with some unreasonably large
            components. My goal for the next game is to clean up my code to make
            it more readable, and rearrange how my setTimeout functions fire.
          </Text>
        </TextBox>
        <Img>
          {" "}
          <Image
            fileName="games-3.png"
            style={{ width: "100%", height: "100%" }}
            alt="the floor is lava game"
          />
        </Img>
      </Flex>
    </Project>
  </>
)

export default GamesWithHue
